import React, { useState } from "react";
import Link from "next/link";
import { Tween } from "react-gsap";
import { Controller, Scene } from "react-scrollmagic";
import { FooterStyles } from "./FooterStyles";
import { useRouter } from "next/router";
import Modal from "../Modal";

const Footer = () => {
  const router = useRouter();
  const pathClass = router.pathname.replace("/", "");
  const [showModal, setShowModal] = useState(false);

  return (
    <FooterStyles className={pathClass || "home"}>
      <Controller>
        <Scene
          duration="50px"
          offset={pathClass ? "-50px" : "0px"}
          triggerElement="#footer-trigger"
          triggerHook={pathClass ? "onEnter" : "onLeave"}
        >
          <Tween
            to={{
              bottom: "0",
            }}
          >
            <div className="footer">
              <div className="footer-container">
                <div className="footer-bottom">
                  <div className="desktop">
                    <div className="col">
                      <span className="copyright">© LONG LEAD</span>
                      <Link href="/privacy-policy" scroll={false} legacyBehavior>
                        <a className="privacy-link">
                          <span>PRIVACY POLICY</span>
                        </a>
                      </Link>
                    </div>
                    <div className="col">
                      <div className="mail desktop">
                        <a href="mailto:info@longlead.com">INFO@LONGLEAD.COM</a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="siteby">
                        SITE BY{" "}
                        <a href="https://decimalstudios.com/" target="_blank" rel="noreferrer">
                          DECIMAL
                        </a>
                      </div>
                    </div>
                    <div className="col">
                      <div className="subscribe" onClick={() => setShowModal(true)}>
                        Subscribe
                      </div>
                    </div>
                  </div>

                  <div className="mobile">
                    <div className="col mobile-button">
                      <div className="subscribe mobile-button" onClick={() => setShowModal(true)}>
                        Subscribe
                      </div>
                    </div>
                    <div className="col">
                      <div className="mail mobile">
                        <a href="mailto:info@longlead.com">INFO@LONGLEAD.COM</a>
                      </div>
                    </div>
                    <div className="col copyright">
                      <span className="copyright">© LONG LEAD</span>
                      <Link href="/privacy-policy" scroll={false} legacyBehavior>
                        <a className="privacy-link">
                          <span>PRIVACY POLICY</span>
                        </a>
                      </Link>
                      <div className="siteby">
                        SITE BY{" "}
                        <a href="https://decimalstudios.com/" target="_blank" rel="noreferrer">
                          DECIMAL
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tween>
        </Scene>
      </Controller>
      {showModal && (
        <Modal setIsOpen={setShowModal}>
          <iframe src="https://longlead.substack.com/embed" width="480" height="320" />
        </Modal>
      )}
    </FooterStyles>
  );
};

export default Footer;
