import styled from "styled-components"

export const ModalStyles = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0rem;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;

  transition: .5s ease all;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .bg-modal {
    display: flex;
    position: absolute;
    left: 0;
    top: 0rem;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.2;
  }

  .modal-container {
    position: relative;
  }

  iframe {
    background: #fff;
    border: 0;
  }

  @media only screen and (max-width: 767px) {
    .modal-container {
      position: absolute;
      left: 0;
      bottom: -100%;
      width: 100%;
      transition: .5s ease all;
      background: #fff;
    }
    &.show {
      .modal-container {
        bottom: 0;
      }
    }

    iframe {
      width: 100%;
    }
  }
  
`
