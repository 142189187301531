import styled from "styled-components";

export const FooterStyles = styled.div`
  background: #f9f7f3;
  overflow: hidden;

  @media only screen and (min-width: 767px) {
    &.home {
      .footer {
        position: fixed;
        bottom: -70px;
        left: 0;
        width: 100%;
      }
    }
  }

  .footer {
    position: relative;
    background: #f9f7f3;
    bottom: -70px;
    padding: 16px 118px 16px 118px;
    line-height: 35px;

    @media only screen and (max-width: 1439px) {
      padding: 16px 90px 16px 90px;
    }

    @media only screen and (max-width: 1079px) {
      padding: 16px 20px 16px 20px;
    }

    @media only screen and (max-width: 767px) {
      padding: 16px 20px;
    }

    .footer-container {
      max-width: 1550px;
      margin: auto;

      @media only screen and (max-width: 1699px) {
        max-width: 1440px;
      }

      @media only screen and (max-width: 1439px) {
        max-width: 1080px;
      }

      .footer-bottom {
        .mobile {
          display: none;
        }

        .desktop {
          display: flex;
          position: relative;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }

        @media only screen and (max-width: 767px) {
          display: block;

          &.footer-top {
            display: block;
          }

          .mobile {
            display: block;
          }

          .desktop {
            display: none;
          }
        }

        .col {
          width: fit-content;

          &.copyright {
            display: flex;
            justify-content: space-between;
          }

          @media only screen and (max-width: 767px) {
            width: 100%;

            &:first-of-type {
              padding-right: 0;
            }

            &.mobile-button {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 10px;
            }

            &.copyright {
              margin-top: 10px;
            }
          }
        }
      }

      .privacy-link {
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 18.2px;
        color: #000;
        margin-left: 17px;
        text-decoration: underline;
        white-space: nowrap;

        @media only screen and (max-width: 767px) {
          font-size: 11px;
          line-height: 14.3px;
          font-weight: 500;
          margin: 0;
        }

        &:hover {
          color: #1f00b7;
        }
      }

      .copyright {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;
        white-space: nowrap;

        @media only screen and (max-width: 767px) {
          font-size: 11px;
          line-height: 14.3px;
          font-weight: 500;
        }
      }

      .mail {
        color: #1f00b7;
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;

        @media only screen and (max-width: 767px) {
          font-size: 11px;
          line-height: 14.3px;
          font-weight: 500;
        }

        a {
          &:hover {
            color: #000;
          }
        }
      }

      .flex {
        display: flex;
        justify-content: space-between;
      }

      .siteby {
        font-size: 14px;
        line-height: 18.2px;
        font-weight: 400;

        @media only screen and (max-width: 767px) {
          font-size: 11px;
          line-height: 14.3px;
          font-weight: 500;
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .subscribe {
    position: relative;
    width: auto;
    height: 38px;
    padding: 0 21px;
    background: #1f00b7;
    color: #fff;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s ease all;
    text-align: center;
    font-family: "Moderat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
    text-transform: uppercase;

    &:hover {
      background: #fff;
      color: #1f00b7;
      svg {
        path {
          stroke: #1f00b7;
        }
      }
    }
  }
`;
