import React, { useEffect, useState } from "react"
import { createPortal } from "react-dom"

import { ModalStyles } from "./ModalStyles"

const FadeModal = ({
  children,
  setIsOpen,
  className = "",
}) => {
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeIn(true)
    }, 50)
    return () => clearTimeout(timer)
  }, [])

  const hidePopup = async () => {
    setFadeIn(false)
    setTimeout(() => {
      setIsOpen(false)
    }, 1000)
  }

  return (
    <ModalStyles className={`${fadeIn ? "show" : "hide"} ${className}`}>
      <div
        className="bg-modal"
        onClick={() => hidePopup()}
      />

      <div className="modal-container">
        <div className="modal-content">{children}</div>
      </div>
    </ModalStyles>
  )
}

const Modal = ({ children, setIsOpen, className }) => {
  return (
    <>
      {createPortal(
        <FadeModal setIsOpen={setIsOpen} className={className}>
          {children}
        </FadeModal>,
        document.getElementById("portal")
      )}
    </>
  )
}

export default Modal
